/* eslint-disable eqeqeq */
import { createActions, Store } from 'reflux'
import Proxies from 'Components/proxies'
import { toast } from 'react-toastify'
const { GET, POST } = Proxies
import _ from 'lodash'
import { download } from 'Components/download'
import Papa from 'papaparse'

export const OrderActions = createActions([
  'getOrders',
  'getOrder',
  'selectOrder',
  'selectOrderLine',
  'refundOrder',
  /* FILTER RELATERET */
  'getSuppliers',
  'getManufacturers',
  'addFilter',
  'updateFilter',
  'removeFilter',
  'fastFilter',
  'applyFilters',
  'shipOrder',
  'printOrder',
  'updateOrder',
  { saveOrder: { children: ['completed'] } },
  'clearFilters',
  'removeFilter',
  'removePreDefinedFilter',
  'addFilterText',
  'updateOrderInstant',
  'expandOrderlines',
  'collapseOrderlines',
  'removeDynamicFilters',
  'removePredefinedFilters',
  'updateOrderGlobal',
  { createCustomOrder: { children: ['completed', 'failed'] } },
  { getUserBalance: { children: ['completed', 'failed'] } },
  'copyToClipboard',
  'clearToast',
  'copyOrderLineTitle',
  'setTooltip',
  'preserveSearch',
  'clearPrintToast',
  'setOrderFilter',
  'preserveAllChecked',
  'selectOrderHandler',
  'bulkUpdateSeasonOrderStatus',
  'getSelectedOrders',
  'logoutModal',
  'selectAllOrderLines',
  'generateRegularShipment',
  'generateDropShipment',
  'handleScrollPosition',
  'handleClick',
  'preservePagination',
  'preservePaginationAmount',
  'addTracking',
  'setTracking',
  'saveTracking',
  'removeTracking',
  'clearC5Error',
  'setRefundFreight',
  'setShowRefundModal'
])

export class OrderStore extends Store {
  constructor(props) {
    super(props)
    this.listenables = OrderActions
    this.state = {
      orders: [],
      fetchingOrders: false,
      fetchingSingleOrder: false,
      orderDetails: [],
      manufacturers: [],
      suppliers: [],
      userBalance: null,
      filters: [],
      seasonOrderFilters: [],
      selectedOrders: [],
      selectedOrderLines: [],
      predefinedFilters: [
        { name: 'Ny', value: 'NEW', selected: true },
        { name: 'Afventer', value: 'TENTATIVE', selected: false },
        { name: 'Backorder', value: 'BACKORDER', selected: false },
        { name: 'Bestilt', value: 'ORDERED', selected: false },
        { name: 'Pakket', value: 'PACKED', selected: false },
        { name: 'Sendt', value: 'SHIPPED', selected: false },
        { name: 'Modtaget', value: 'RECEIVED', selected: false },
        { name: 'Refunderet', value: 'REFUNDED', selected: false },
        { name: 'Reklamation', value: 'COMPLAINT', selected: false },
        { name: 'Afvist', value: 'DECLINED', selected: false },
        { name: 'Fejlet', value: 'FAILED', selected: false },
        { name: 'Preorder', value: 'PREORDER', selected: false }
      ],
      seasonOrderPredefinedFilters: [
        { name: 'Ny', value: 'NEW', selected: true },
        { name: 'Afventer', value: 'TENTATIVE', selected: false },
        { name: 'Skal bestilles', value: 'TO_ORDER', selected: false },
        { name: 'Bestilt', value: 'ORDERED', selected: false },
        { name: 'Modtaget', value: 'RECEIVED', selected: false },
        { name: 'Pakket', value: 'PACKED', selected: false },
        { name: 'Sendt', value: 'SHIPPED', selected: false }
      ],
      orderStatus: '',
      paginationPoint: 0,
      paginationAmount: 100,
      showTooltip: false,
      filterText: '',
      showClipBoardMessage: false,
      fetchingPrintOrderTemplate: true,
      printOrderTemplate: null,
      fetchingPrintOrderTemplateFailed: false,
      showPrintDialog: false,
      printErrorMessage: null,
      isCustomOrder: null,
      orderFilter: 'order',
      filterName: 'filters',
      isAllChecked: false,
      isAllOrderLinesChecked: false,
      isIndeterminate: false,
      c5SelectedOrders: [],
      previewOrders: false,
      scrollPosition: null,
      refundedOrder: {},
      c5SupplierError: null,
      showRefundModal: false,
      refundFreight: false,
      OrderID: null
    }
  }
  setRefundFreight(e) {
    this.setState({ refundFreight: e.target.checked })
  }
  setShowRefundModal(showModal, OrderID) {
    this.setState({ showRefundModal: showModal, OrderID })
  }
  setOrderFilter(orderFilter) {
    this.setState({ orderFilter, filterName: orderFilter === 'order' ? 'filters' : 'seasonOrderFilters' })
  }
  handleClick() {
    this.setState({ scrollPosition: window.pageYOffset })
  }
  preservePagination(paginationPoint) {
    this.setState({ paginationPoint })
  }
  preservePaginationAmount(paginationAmount) {
    this.setState({ paginationAmount })
  }
  getOrders() {
    this.setState({ fetchingOrders: true })
    let nextState = {
      fetchingOrders: false,
      orders: [],
      [this.state.filterName]: this.state[this.state.filterName],
      selectedOrderLines: this.state.selectedOrderLines,
      predefinedFilters: this.state.predefinedFilters,
      seasonOrderPredefinedFilters: this.state.seasonOrderPredefinedFilters
    }
    let filters = this.state[this.state.filterName]
      .filter(e => {
        return e.column != '' && e.value != '' && e.column !== 'orderFlowStatus'
      })
      .map(e => {
        return { column: e.column, operator: e.operator ? e.operator : 'LIKE', value: e.value }
      })
    let statusFilter = this.state[this.state.filterName].filter(e => e.column === 'orderFlowStatus')
    if (statusFilter.length) {
      statusFilter = statusFilter.map(e => e.value)
    }
    if (statusFilter.length) {
      filters.push({ column: 'orderFlowStatus', operator: 'LIKE', value: statusFilter })
    }

    if (!filters.length) {
      filters.push({ column: 'orderFlowStatus', operator: 'LIKE', value: ['NEW'] })
      nextState[this.state.filterName].push({ filterID: 0, column: 'orderFlowStatus', operator: 'LIKE', value: 'NEW' })
    }
    if (this.state.orderFilter === 'seasonOrder') {
      nextState.seasonOrderPredefinedFilters = this.refreshPredefinedFilters('seasonOrderPredefinedFilters')
    } else {
      nextState.predefinedFilters = this.refreshPredefinedFilters('predefinedFilters')
    }

    filters.push(
      this.state.orderFilter === 'seasonOrder'
        ? { column: 'orderType', operator: 'LIKE', value: 'SEASON' }
        : { column: 'orderType', operator: 'LIKE', value: 'TRANSACTION' }
    )
    GET('orders/admin-pool', { filter: filters }).then(responseJSON => {
      if (responseJSON) {
        responseJSON.forEach(element => {
          element.checked = false
        })
        nextState.orders = responseJSON
        nextState.selectedOrderLines = []
        nextState.isAllChecked = false
        nextState.isAllOrderLinesChecked = false
      }
      this.setState(nextState)
    })
  }
  getOrder(OrderID = null) {
    if (OrderID == null) {
      return false
    }
    this.setState({ fetchingSingleOrder: true })
    let nextState = {
      fetchingSingleOrder: false,
      orderDetails: []
    }
    GET('orders/fetch', { OrderID: OrderID }).then(responseJSON => {
      if (responseJSON) {
        nextState.orderDetails = responseJSON
        this.setState({ isCustomOrder: responseJSON.orderCustomOrder })
      }
      this.setState(nextState)
    })
  }
  selectOrder(OrderID) {
    let selectedOrders = this.state.selectedOrders
    let index = selectedOrders.findIndex(e => {
      return e == OrderID
    })
    if (index != -1) {
      selectedOrders.splice(index, 1)
    } else {
      selectedOrders.push(OrderID)
    }
    this.setState({ selectedOrders: selectedOrders })
  }
  selectOrderLine(OrderLineID, orderlines = []) {
    let selectedOrderLines = this.state.selectedOrderLines
    let isAllOrderLinesChecked = this.state.isAllOrderLinesChecked
    let isIndeterminate = this.state.isIndeterminate
    let OrderlineIDs = [OrderLineID]
    if (orderlines.length) {
      OrderlineIDs = orderlines.map(ol => ol.OrderlineID)
    }
    for (OrderLineID of OrderlineIDs) {
      let index = selectedOrderLines.findIndex(e => {
        return e == OrderLineID
      })
      if (index != -1) {
        selectedOrderLines.splice(index, 1)
        if (isAllOrderLinesChecked) {
          isAllOrderLinesChecked = false
          isIndeterminate = true
        }
      } else {
        selectedOrderLines.push(OrderLineID)
      }
      this.setState({ selectedOrderLines: selectedOrderLines, isAllOrderLinesChecked: isAllOrderLinesChecked, isIndeterminate: isIndeterminate })
    }
  }
  refundOrder(OrderID, refundFreight = false) {
    let order = {}
    order[OrderID] = true
    this.setState({ refundedOrder: order })
    POST('orders/refund', { OrderID, refundFreight: refundFreight ? 1 : 0 })
      .then(response => {
        this.setState({ refundedOrder: {}, refundFreight: false, OrderID: null })
        if (response.error) {
          this.setState({ orderStatus: false })
        } else {
          this.setState({ orderStatus: true })
          this.getOrders()
        }
      })
      .catch(() => this.setState({ refundedOrder: {} }))
  }
  getManufacturers() {
    GET('categories/pool', { categoryTaxonomy: 'MANUFACTURER' }).then(responseJSON => {
      if (responseJSON) {
        this.setState({ manufacturers: responseJSON })
      }
    })
  }

  getSuppliers() {
    GET('suppliers/pool').then(responseJSON => {
      if (responseJSON) {
        this.setState({ suppliers: responseJSON })
      }
    })
  }
  shipOrder(OrderID, isNavigate = false, props) {
    POST('orders/ship', { OrderID: OrderID }).then(responseJSON => {
      if (responseJSON.error) {
        this.setState({ orderStatus: false })
      } else {
        isNavigate && props.history.push('/orders')
        this.setState({ orderStatus: true })
        this.getOrders()
      }
    })
  }
  printOrder(OrderID) {
    if (OrderID) {
      this.setState({ fetchingPrintOrderTemplate: true, printErrorMessage: null })
      POST('orders/print', { OrderID: OrderID }).then(responseJSON => {
        if (responseJSON.error) {
          this.setState({ fetchingPrintOrderTemplateFailed: true, printErrorMessage: responseJSON.error })
        } else {
          this.setState({ fetchingPrintOrderTemplate: false, showPrintDialog: true, printOrderTemplate: responseJSON })
        }
      })
    }
  }
  saveOrder(orderDetailsForm) {
    return POST('orders/update', {
      OrderIDs: [this.state.orderDetails.OrderID],
      orderFlowStatus: orderDetailsForm.orderFlowStatus,
      orderWeight: orderDetailsForm.orderWeight,
      orderParcels: orderDetailsForm.orderParcels,
      orderDropShipment: orderDetailsForm.orderDropShipment,
      orderComment: orderDetailsForm.orderComment,
      orderConsignee: orderDetailsForm.orderConsignee,
      orderConsigneeName: orderDetailsForm.orderConsigneeName,
      orderConsigneeAddress1: orderDetailsForm.orderConsigneeAddress1,
      orderConsigneeAddress2: orderDetailsForm.orderConsigneeAddress2,
      orderConsigneeZipcode: orderDetailsForm.orderConsigneeZipcode,
      orderConsigneeCity: orderDetailsForm.orderConsigneeCity,
      orderConsigneeCountry: orderDetailsForm.orderConsigneeCountry,
      orderConsigneeEmail: orderDetailsForm.orderConsigneeEmail,
      orderConsigneePhone: orderDetailsForm.orderConsigneePhone,
      orderRemarks: orderDetailsForm.orderRemarks,
      ParcelshopID: orderDetailsForm.ParcelshopID ? parseInt(orderDetailsForm.ParcelshopID) : undefined,
      orderGlsCustomerNumber: orderDetailsForm.orderGlsCustomerNumber,
      userOrderRemarks: orderDetailsForm.userOrderRemarks,
      AddressID: Number(orderDetailsForm.AddressID)
    }).then(responseJSON => {
      if (responseJSON && !responseJSON.error) {
        this.setState({ orderDetails: responseJSON })
      }
      OrderActions.saveOrder.completed()
    })
  }
  updateOrder(field) {
    const name = field.target.name
    let value = field.target.value
    let orderDetails = this.state.orderDetails
    orderDetails[name] = value
    this.setState({ orderDetails })
  }
  updateOrderInstant(field, order) {
    let value = field.target.value
    let allOrders = this.state.orders
    let refreshOrder = allOrders.filter(o => o.OrderID === order.OrderID)[0]
    refreshOrder.orderFlowStatus = value
    POST('orders/update', { OrderIDs: [order.OrderID], orderFlowStatus: value }).then(responseJSON => {
      if (!responseJSON.error) {
        this.setState({ orderStatus: true })
        this.getOrders()
      } else {
        this.setState({ orderStatus: false })
      }
    })
  }
  addFilter(data = {}, update = false) {
    let filters = this.state[this.state.filterName]
    let lastFilterID = -1
    if (filters.length > 0) {
      let filterIDs = filters.map(e => {
        return e.filterID
      })
      lastFilterID = filterIDs.reduce((a, b) => {
        return Math.max(a, b)
      })
    }
    let filter = { filterID: lastFilterID + 1 }
    if (!data.column) {
      data = { column: '', operator: '', value: '' }
    }
    Object.assign(filter, data)
    filters.push(filter)
    this.setState({ [this.state.filterName]: filters })
    if (update) {
      this.applyFilters()
    }
  }
  removeFilter(column, update = false) {
    let filters = this.state[this.state.filterName]
    filters = filters.filter(e => {
      return e.column != column
    })
    this.setState({ [this.state.filterName]: filters })
    if (update || filters.length === 0) {
      this.applyFilters()
    }
  }
  updateFilter(editFilter, field) {
    let filterID = editFilter.filterID
    let fieldName = field.target.name
    let fieldValue = field.target.value
    let filters = this.state[this.state.filterName]
    for (let filter of filters) {
      if (filter.filterID == filterID) {
        filter[fieldName] = fieldValue
        if (fieldName == 'column') {
          filter.value = ''
        }
      }
    }
    this.setState({ [this.state.filterName]: filters })
  }
  fastFilter(filterData) {
    let nextState = { filters: this.state[this.state.filterName], predefinedFilters: this.state.predefinedFilters }
    nextState[this.state.filterName] = [filterData]
    this.setState(nextState)
    this.applyFilters()
  }
  applyFilters() {
    // if (filters) {
    //   this.setState({ filters: filters })
    // }
    this.getOrders()
  }
  clearFilters() {
    this.setState({ filters: [], seasonOrderFilters: [] })
  }
  removePreDefinedFilter(filterData) {
    let filters = this.state[this.state.filterName]
    filters = filters.filter(fil => !(fil.column == filterData.column && fil.value === filterData.value))
    this.setState({ [this.state.filterName]: filters })
    this.applyFilters()
  }
  refreshPredefinedFilters(filter) {
    let statusFilters = this.state[this.state.filterName].filter(fil => fil.column === 'orderFlowStatus')
    let predefinedFilters = this.state[filter]
    predefinedFilters = predefinedFilters.map(fil => {
      fil.selected = false
      return fil
    })
    for (let fil of predefinedFilters) {
      for (let f of statusFilters) {
        if (fil.value === f.value) {
          fil.selected = true
        }
      }
    }
    return predefinedFilters
  }
  expandOrderlines(ol) {
    let orders = this.state.orders
    let order = orders.filter(ord => ord.OrderID === ol.OrderID)
    if (order.length && order[0].orderlines) {
      let orderline = order[0].orderlines.filter(line => line.orderlineTitle === ol.orderlineTitle)
      if (orderline.length) {
        orderline[0].grouped = false
        this.setState({ orders: orders })
      }
    }
  }
  collapseOrderlines(ol) {
    let orders = this.state.orders
    let order = orders.filter(ord => ord.OrderID === ol.OrderID)
    if (order.length) {
      for (let line of order[0].orderlines) {
        if (line.orderlineTitle === ol.orderlineTitle) {
          line.grouped = true
        }
      }
      this.setState({ orders: orders })
    }
  }
  updateOrderGlobal(item, orderStatus) {
    let value = item.target.value
    if (orderStatus[value] && value && this.state.selectedOrderLines.length) {
      POST('orders/splice', { orderlines: this.state.selectedOrderLines, orderFlowStatus: value }).then(responseJSON => {
        if (responseJSON && !responseJSON.error) {
          this.getOrders()
          toast.success('Orderline status changed successfully.', {
            autoClose: 3000,
            onClose: () => {
              OrderActions.clearToast()
            }
          })
        }
      })
    }
  }
  bulkUpdateSeasonOrderStatus(item, bulkSelectedOrders = []) {
    let status = item.target.value
    item.target.value = false // reset the dropdown
    if (bulkSelectedOrders.length > 0) {
      POST('orders/change-status', { status, ordersArray: bulkSelectedOrders }).then(response => {
        if (response && !response.error) {
          this.getOrders()
          toast.success('Order status changed successfully.', {
            autoClose: 3000,
            onClose: () => {
              OrderActions.clearToast()
            }
          })
        }
        if (response && response.error) {
          toast.error('Error when changing status', {
            autoClose: 3000,
            onClose: () => {
              OrderActions.clearToast()
            }
          })
        }
      })
    } else {
      toast.error('No orders were selected', {
        autoClose: 7000,
        onClose: () => {
          OrderActions.clearToast()
        }
      })
    }
  }
  createCustomOrder(order) {
    if (order.brandIdentifier && order.userIdentifier) {
      POST('orders/custom-order', order).then(responseJSON => {
        if (responseJSON && !responseJSON.error) {
          let orders = this.state.orders
          orders.unshift(responseJSON)
          this.setState({ orders })
          OrderActions.createCustomOrder.completed()
        } else {
          OrderActions.createCustomOrder.failed(responseJSON.error)
        }
      })
    }
  }

  getUserBalance(order) {
    if (order.brandIdentifier && order.userIdentifier) {
      POST('orders/get-user-balance', order).then(responseJSON => {
        if (responseJSON && !responseJSON.error) {
          let userBalance = this.state.userBalance
          userBalance = responseJSON
          this.setState({ userBalance })
          OrderActions.getUserBalance.completed(userBalance)
        } else {
          OrderActions.getUserBalance.failed(responseJSON.error)
        }
      })
    }
  }
  copyToClipboard() {
    var range = document.createRange()
    range.selectNode(document.getElementById('deliveryInfo'))
    window.getSelection().removeAllRanges() // clear current selection
    window.getSelection().addRange(range) // to select text
    document.execCommand('copy')
    window.getSelection().removeAllRanges() // to deselect
  }

  copyOrderLineTitle(total, value) {
    this.setState({ showTooltip: false })
    if (total !== value) {
      var range = document.createElement('textarea')
      document.body.appendChild(range)
      range.value = value
      range.select()
      document.execCommand('copy')
      document.body.removeChild(range)
      this.setState({ showTooltip: true })
    }
  }

  clearToast() {
    this.setState({ orderStatus: null, showClipBoardMessage: null, fetchingPrintOrderTemplateFailed: false })
  }

  setTooltip() {
    this.setState({ showTooltip: false })
  }

  preserveSearch(filterText) {
    this.setState({ filterText })
  }

  clearPrintToast() {
    this.setState({ showPrintDialog: false })
  }
  preserveAllChecked(isAllChecked) {
    this.setState({ isAllChecked: isAllChecked })
  }
  selectOrderHandler(event, OrderID) {
    let orders = this.state.orders
    let isAllChecked = this.state.isAllChecked
    orders.forEach(element => {
      if (element.OrderID === OrderID) {
        element.checked = event.target.checked
      }
    })
    if (event.target.checked === false && isAllChecked) {
      isAllChecked = false
    }
    this.setState({ orders, isAllChecked })
  }

  getSelectedOrders() {
    let c5SelectedOrders = []
    let supplier = null
    let multipleSuppliers = false
    this.state.orders.map(order => {
      if (order && order.orderlines.length > 0) {
        order.orderlines.map(item => {
          if (this.state.selectedOrderLines.includes(item.OrderlineID)) {
            if (item.product && item.product.supplier) {
              if (!!supplier && supplier !== item.product.supplier.supplierUID) {
                multipleSuppliers = true
              }
              supplier = item.product.supplier.supplierUID
            }
            if (supplier) {
              c5SelectedOrders.push({
                OrderID: order.OrderID,
                OrderlineID: item.OrderlineID,
                UserID: order.UserID,
                ProductID: item.variationUID,
                address: `${order.orderConsignee}, ${order.orderConsigneeName}, ${order.orderConsigneeAddress1}, ${order.orderConsigneeZipcode} ${order.orderConsigneeCity}`,
                email: order.orderConsigneeEmail,
                phone: order.orderConsigneePhone,
                count: 1,
                vendor: supplier,
                c5Exported: item.c5Exported
              })
            }
          }
        })
      }
      return order
    })
    if (!supplier || multipleSuppliers) {
      this.setState({
        c5SupplierError: !supplier ? 'No Supplier for selected orderlines.' : 'Multiple suppliers selected. Select orderlines from only one supplier.'
      })
    } else {
      this.setState({ c5SelectedOrders, previewOrders: true })
    }
  }
  clearC5Error() {
    this.setState({ c5SupplierError: null })
  }
  logoutModal() {
    this.setState({ previewOrders: false })
  }
  selectAllOrderLines(e) {
    let selectedOrderLines = []
    if (e.target.checked) {
      this.state.orders.map(order => {
        if (order && order.orderlines.length > 0) {
          order.orderlines.map(item => selectedOrderLines.push(item.OrderlineID))
        }
      })
    }
    this.setState({ selectedOrderLines, isAllOrderLinesChecked: e.target.checked, isIndeterminate: false })
  }
  saveC5Orderlines(c5SelectedOrders, isDropship = false) {
    const OrderlineIDs = c5SelectedOrders.map(item => item.OrderlineID)
    POST('orders/saveC5Orderlines', { OrderlineIDs, isDropship }).then(responseJSON => {
      if (responseJSON.error) {
        toast.error('Could not mark Orderlines as exported to PO system', {
          autoClose: 3000,
          onClose: () => {
            OrderActions.clearToast()
          }
        })
      } else {
        toast.success('Marked orderlines as exported to PO System', {
          autoClose: 3000,
          onClose: () => {
            OrderActions.clearToast()
          }
        })
      }
    })
    let selectedOrdersForStatus = []
    let selectedOrderlinesForSplice = []
    const c5ByOrderID = _.groupBy(c5SelectedOrders, 'OrderID')
    for (let OrderID in c5ByOrderID) {
      const orderlinesByOrderID = c5ByOrderID[OrderID]
      const originalOrder = this.state.orders.find(o => o.OrderID === +OrderID)
      if (originalOrder.orderlines.length === orderlinesByOrderID.length) {
        let userOrderlineMatch = selectedOrderlinesForSplice.find(item => item.UserID === originalOrder.UserID)
        if (userOrderlineMatch) {
          selectedOrderlinesForSplice = [...selectedOrderlinesForSplice, ...orderlinesByOrderID]
          continue
        }
        let userOrderMatch = selectedOrdersForStatus.find(item => item.UserID === originalOrder.UserID)
        if (userOrderMatch) {
          selectedOrdersForStatus = selectedOrdersForStatus.filter(item => item.OrderID !== userOrderMatch.OrderID)
          selectedOrderlinesForSplice = [...selectedOrderlinesForSplice, ...orderlinesByOrderID, ...userOrderMatch.orderlines]
        } else {
          selectedOrdersForStatus.push(originalOrder)
        }
      }
      if (originalOrder.orderlines.length > orderlinesByOrderID.length) {
        selectedOrderlinesForSplice = [...selectedOrderlinesForSplice, ...orderlinesByOrderID]
      }
    }
    if (selectedOrdersForStatus.length) {
      POST('orders/change-status', { status: isDropship ? 'ORDERED' : 'ORDERED', ordersArray: selectedOrdersForStatus }).then(response => {
        if (response && !response.error) {
          this.getOrders()
          toast.success(`Order status changed successfully to ${isDropship ? 'ORDERED' : 'ORDERED'}.`, {
            autoClose: 3000,
            onClose: () => {
              OrderActions.clearToast()
            }
          })
        }
      })
    }
    if (selectedOrderlinesForSplice.length) {
      POST('orders/splice', {
        orderlines: selectedOrderlinesForSplice.map(item => item.OrderlineID),
        orderFlowStatus: isDropship ? 'ORDERED' : 'ORDERED'
      }).then(responseJSON => {
        if (responseJSON && !responseJSON.error) {
          this.getOrders()
          toast.success(`Orderlines status changed successfully to ${isDropship ? 'ORDERED' : 'ORDERED'}.`, {
            autoClose: 3000,
            onClose: () => {
              OrderActions.clearToast()
            }
          })
        }
      })
    }
  }
  generateRegularShipment() {
    let CSVData = []
    let c5SelectedOrders = this.state.c5SelectedOrders
      .filter(item => !item.c5Exported)
      .map(item => {
        item.address = 'Ellekær 6, 2730 Herlev'
        return item
      })
    if (!c5SelectedOrders.length) {
      toast.error('No orderlines to export', {
        autoClose: 3000,
        onClose: () => {
          OrderActions.clearToast()
        }
      })
      return
    }
    let orderLines = _.groupBy(c5SelectedOrders, item => {
      return [item['ProductID'], item['address']]
    })
    Object.keys(orderLines).map((item, index) => {
      let rec = orderLines[item][0]
      index === 0 && CSVData.push(['ProductID', 'Delivery Address', 'Count', 'Note'])
      CSVData.push([rec.ProductID, rec.address, orderLines[item].length, ''])
    })
    let date = new Date()
      .toISOString()
      .split('T')
      .map(item => item.replace(/\D/g, ''))
    let fileName = `${date.join('_')}_${c5SelectedOrders[0].vendor}_REGULAR.CSV`

    let papasettings = {
      quotes: true,
      delimiter: ';',
      header: true
    }
    let csvDataString = ''
    CSVData.map(item => {
      if (item) {
        let data = item.join(';')
        csvDataString = csvDataString + data + ';\n'
      }
    })
    download(Papa.unparse(CSVData, papasettings), fileName)
    this.saveC5Orderlines(c5SelectedOrders)
    POST('orders/save-c5data-s3', { file: { csvDataString }, filename: fileName }).then(responseJSON => {
      if (responseJSON.error) {
        toast.error('Could not save file to S3', {
          autoClose: 3000,
          onClose: () => {
            OrderActions.clearToast()
          }
        })
      } else {
        toast.success('Uploaded file to S3', {
          autoClose: 3000,
          onClose: () => {
            OrderActions.clearToast()
          }
        })
      }
    })
    this.setState({ isAllOrderLinesChecked: false, selectedOrderLines: [], c5SelectedOrders: [] })
    this.logoutModal()
  }
  generateDropShipment() {
    let CSVData = []
    let c5SelectedOrders = this.state.c5SelectedOrders.filter(item => !item.c5Exported)
    if (!c5SelectedOrders.length) {
      toast.error('No orderlines to export', {
        autoClose: 3000,
        onClose: () => {
          OrderActions.clearToast()
        }
      })
      return
    }
    let orderLines = _.groupBy(c5SelectedOrders, 'UserID')
    Object.keys(orderLines).map((item, index) => {
      let orders = orderLines[item]
      let data = _.groupBy(orders, 'ProductID')
      index === 0 && CSVData.push(['ProductID', 'Delivery Address', 'Count', 'Note'])
      Object.keys(data).map((order, ind) => {
        if (order !== 'OrderlineID' && order !== 'c5Exported' && order !== 'UserID' && order !== 'email' && order !== 'phone') {
          let lines = data[order]
          CSVData.push([
            order,
            'See Note',
            `${lines.length}`,
            ind === Object.keys(data).length - 1 ? `${lines[0].address}, Email: ${lines[0].email}, Phone: ${lines[0].phone}` : ''
          ])
        }
      })
    })
    let date = new Date()
      .toISOString()
      .split('T')
      .map(item => item.replace(/\D/g, ''))
    let fileName = `${date.join('_')}_${this.state.c5SelectedOrders[0].vendor}_DROPSHIP.CSV`
    let papasettings = {
      quotes: true,
      delimiter: ';',
      header: true
    }
    download(Papa.unparse(CSVData, papasettings), fileName)
    let csvDataString = ''
    CSVData.map(item => {
      if (item) {
        let data = item.join(';')
        csvDataString = csvDataString + data + ';\n'
      }
    })
    this.saveC5Orderlines(c5SelectedOrders, true)
    POST('orders/save-c5data-s3', { file: { csvDataString }, filename: fileName }).then(responseJSON => {
      if (responseJSON.error) {
        toast.error('Could not save file to S3', {
          autoClose: 3000,
          onClose: () => {
            OrderActions.clearToast()
          }
        })
      } else {
        toast.success('Uploaded file to S3', {
          autoClose: 3000,
          onClose: () => {
            OrderActions.clearToast()
          }
        })
      }
    })
    this.setState({ isAllOrderLinesChecked: false, selectedOrderLines: [], c5SelectedOrders: [] })
    this.logoutModal()
  }
  addTracking() {
    let nextState = { orderDetails: this.state.orderDetails }
    if (!nextState.orderDetails.timeline) {
      nextState.orderDetails.timeline = []
    }
    nextState.orderDetails.timeline.push({
      parentRelation: 'Order',
      parentRelationID: this.state.orderDetails.OrderID,
      timelineData: '',
      timelineEvent: 'TRACKED',
      timelineType: 'update'
    })
    this.setState(nextState)
  }
  setTracking(index, element) {
    let nextState = { orderDetails: this.state.orderDetails }
    let timeline = nextState.orderDetails.timeline[index]
    timeline.timelineData = element.target.value
    this.setState(nextState)
  }
  saveTracking(item) {
    POST('orders/add-tracking', item).then(() => {
      this.getOrder(this.state.orderDetails.OrderID)
    })
  }
  removeTracking(item, index) {
    if (item.TimelineID) {
      POST('orders/remove-tracking', { TimelineID: item.TimelineID }).then(() => {
        this.getOrder(this.state.orderDetails.OrderID)
      })
    } else {
      let nextState = { orderDetails: this.state.orderDetails }
      nextState.orderDetails.timeline.splice(index, 1)
      this.setState(nextState)
    }
  }
}
