import React, { useEffect, useState } from 'react'
import Reflux, { Component } from 'reflux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Row } from 'reactstrap'
import { Fade, Form, FormGroup, Input, Label, Col, CustomInput } from 'reactstrap'
import { BrandActions, BrandStore, STATUS } from 'Stores/brandStore'
import _ from 'lodash'
import styled from 'styled-components'
import { StyledDropdown } from '../campaigns/SantanderCampaignUserSelect'
import Proxies from 'Components/proxies'

const StyledCustomInput = styled(CustomInput)`
  top: 6px !important;
`
export default class BrandForm extends Component {
  constructor() {
    super()
    this.store = BrandStore
  }

  validField() {
    const {
      ClientID,
      brandName,
      brandNameShort,
      brandNickname,
      brandSlug,
      brandColor,
      brandLogo,
      brandIconImageURL,
      brandFeatureImageURL,
      brandPointRatio,
      brandShopSelectionLimit,
      poolOrder,
      brandAccessTermsURL,
      brandAccessKey
    } = this.state.brandDetails
    return (
      /^[0-9]{1,}$/.test(ClientID) &&
      /^[a-zøåæA -ZØÅÆ_0-9-]{1,255}$/.test(brandName) &&
      /^[a-zøåæA -ZØÅÆ_0-9-]{1,11}$/.test(brandNameShort) &&
      /^[a-zøåæA -ZØÅÆ_0-9-]{1,11}$/.test(brandNickname) &&
      /^[a-zA-Z_0-9-]{1,20}$/.test(brandSlug) &&
      /^[a-zA-Z_0-9-]{6,6}$/.test(brandColor) &&
      /^(|https?:\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-.,@?^=%&amp;:/~+#]*[\w\-@?^=%&amp;/~+#])?)$/.test(brandAccessTermsURL) &&
      /^(|https?:\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-.,@?^=%&amp;:/~+#]*[\w\-@?^=%&amp;/~+#])?)$/.test(brandLogo) &&
      /^(|https?:\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-.,@?^=%&amp;:/~+#]*[\w\-@?^=%&amp;/~+#])?)$/.test(brandIconImageURL) &&
      /^(|https?:\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-.,@?^=%&amp;:/~+#]*[\w\-@?^=%&amp;/~+#])?)$/.test(brandFeatureImageURL) &&
      (/^[0-9-]{6,6}$/.test(brandAccessKey) || brandAccessKey.length < 1) &&
      /^[0-9.]{1,}$/.test(brandPointRatio) &&
      /^[0-9-]{1,}$/.test(brandShopSelectionLimit) &&
      /^[0-9-]{1,}$/.test(poolOrder)
    )
  }
  // brandData.features.cookiePageClub.header
  componentDidMount() {
    BrandActions.getLanguages()
    BrandActions.getFonts()
  }

  resetForm = () => {
    BrandActions.resetFormValidations()
    if (this.props.match.params.BrandID) {
      BrandActions.getBrandDetails(this.props.match.params.BrandID)
    } else {
      BrandActions.resetForm()
    }
  }

  render() {
    const settings = Reflux.GlobalState.LoginStore.settings
    const showThemeField = settings && settings.features && settings.features.enableThemeCustomisation ? true : false
    const formIsValid = this.validField()
    return (
      <Form>
        <FormGroup row>
          <Label className='required' for='ClientID' sm={2}>
            Klient
          </Label>
          <Col sm={10}>
            <CustomInput type='select' id='ClientID' name='ClientID' value={this.state.brandDetails.ClientID} onChange={BrandActions.updateField}>
              <option value={Number(-1)}>Vælg en klient</option>
              {this.state.clientlist.map((e, i) => {
                return (
                  <option value={Number(e.ClientID)} key={i}>
                    {e.ClientID} - {e.clientName}
                  </option>
                )
              })}
            </CustomInput>
          </Col>
        </FormGroup>

        {_.get(this.props, 'match.params.BrandID') && (
          <FormGroup row>
            <Label for='isDownForMaintenance' sm={2}>
              Down For Maintenance
            </Label>
            <Col sm={10}>
              <StyledCustomInput
                type='switch'
                checked={this.state.customisation.isDownForMaintenance}
                id='isDownForMaintenance'
                name='isDownForMaintenance'
                onChange={BrandActions.updateCustomisation}
              />
            </Col>
          </FormGroup>
        )}
        <FormGroup row>
          <Label for='excludeInvoice' sm={2}>
            Exclude from Invoice
          </Label>
          <Col sm={10}>
            <StyledCustomInput
              type='switch'
              checked={this.state.brandDetails.excludeInvoice}
              id='excludeInvoice'
              name='excludeInvoice'
              onChange={BrandActions.updateField}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for='excludeFreight' sm={2}>
            Exclude from Freight
          </Label>
          <Col sm={10}>
            <StyledCustomInput
              type='switch'
              checked={this.state.brandDetails.excludeFreight}
              id='excludeFreight'
              name='excludeFreight'
              onChange={BrandActions.updateField}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for='walletActivation' sm={2}>
            Activation Wallet
          </Label>
          <Col sm={1}>
            <StyledCustomInput
              type='switch'
              checked={this.state.walletActivation && this.state.walletActivation.enableWalletActivation ? true : false}
              id='enableWalletActivation'
              name='enableWalletActivation'
              onChange={BrandActions.updateWalletActivationCustomisation}
            />
          </Col>
          <Label for='walletActivation' sm={1}>
            Disable Grid Section
          </Label>
          <Col sm={1}>
            <StyledCustomInput
              type='switch'
              checked={this.state.walletActivation && this.state.walletActivation.disableWalletActivationGridSection ? true : false}
              id='disableWalletActivationGridSection'
              name='disableWalletActivationGridSection'
              onChange={BrandActions.updateWalletActivationCustomisation}
            />
          </Col>
          <Label for='walletActivation' sm={1}>
            Disable Auto Activate
          </Label>
          <Col sm={1}>
            <StyledCustomInput
              type='switch'
              checked={this.state.walletActivation && this.state.walletActivation.disableAutoActivateFeature ? true : false}
              id='disableAutoActivateFeature'
              name='disableAutoActivateFeature'
              onChange={BrandActions.updateWalletActivationCustomisation}
            />
          </Col>
          <Label for='walletActivation' sm={1}>
            Disable User Activate
          </Label>
          <Col sm={1}>
            <StyledCustomInput
              type='switch'
              checked={this.state.walletActivation && this.state.walletActivation.disableActivateWalletFeature ? true : false}
              id='disableActivateWalletFeature'
              name='disableActivateWalletFeature'
              onChange={BrandActions.updateWalletActivationCustomisation}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for='enableSendPoints' sm={2}>
            Enable Send points
          </Label>
          <Col sm={1}>
            <StyledCustomInput
              type='switch'
              checked={
                this.state.sendPointsCustomisation &&
                this.state.sendPointsCustomisation.sendPoints &&
                this.state.sendPointsCustomisation.sendPoints.enableSendPoints
                  ? true
                  : false
              }
              id='enableSendPoints'
              name='enableSendPoints'
              onChange={BrandActions.updateSendPointsCustomisation}
            />
          </Col>
          <Label for='enableLoanPoints' sm={1}>
            Enable Loan
          </Label>
          <Col sm={2}>
            <StyledCustomInput
              disabled={
                !(
                  this.state.sendPointsCustomisation &&
                  this.state.sendPointsCustomisation.sendPoints &&
                  this.state.sendPointsCustomisation.sendPoints.enableSendPoints
                )
              }
              type='switch'
              checked={
                this.state.sendPointsCustomisation &&
                this.state.sendPointsCustomisation.sendPoints &&
                this.state.sendPointsCustomisation.sendPoints.enableLoanPoints
                  ? true
                  : false
              }
              id='enableLoanPoints'
              name='enableLoanPoints'
              onChange={BrandActions.updateSendPointsCustomisation}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for='disableProfileStatistic' sm={2}>
            Disable Profile Statistic
          </Label>
          <Col sm={10}>
            <StyledCustomInput
              type='switch'
              checked={this.state.profileCustomisation && this.state.profileCustomisation.disableProfileStatistic ? true : false}
              id='disableProfileStatistic'
              name='disableProfileStatistic'
              onChange={BrandActions.updateProfileCustomisation}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for='disableProfileWishlist' sm={2}>
            Disable Profile Wishlist
          </Label>
          <Col sm={10}>
            <StyledCustomInput
              type='switch'
              checked={this.state.profileCustomisation && this.state.profileCustomisation.disableProfileWishlist ? true : false}
              id='disableProfileWishlist'
              name='disableProfileWishlist'
              onChange={BrandActions.updateProfileCustomisation}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for='disableProfilePointsOverview' sm={2}>
            Disable Profile Point Overview
          </Label>
          <Col sm={10}>
            <StyledCustomInput
              type='switch'
              checked={this.state.profileCustomisation && this.state.profileCustomisation.disableProfilePointsOverview ? true : false}
              id='disableProfilePointsOverview'
              name='disableProfilePointsOverview'
              onChange={BrandActions.updateProfileCustomisation}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for='brandEnableTaxInfo' sm={2}>
            Enable Tax Info
          </Label>
          <Col sm={10}>
            <StyledCustomInput
              type='switch'
              checked={this.state.brandDetails && this.state.brandDetails.brandEnableTaxInfo ? true : false}
              id='brandEnableTaxInfo'
              name='brandEnableTaxInfo'
              onChange={BrandActions.updateField}
            />
          </Col>
        </FormGroup>
        <hr />
        <FormGroup row>
          <Label className='required' for='brandName' sm={2}>
            Navn
          </Label>
          <Col sm={10}>
            <Input
              required
              className={this.state.brandNameError ? 'is-invalid' : ''}
              type='Text'
              name='brandName'
              id='brandName'
              placeholder='fx. MsBranding'
              value={this.state.brandDetails.brandName}
              onChange={BrandActions.updateField}
              onBlur={BrandActions.fieldBlur}
            />
            <Fade in={this.state.brandNameError ? true : false} tag='small' className='error-banner'>
              Indtast mellem 1-225 tegn
            </Fade>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label className='required' for='brandSlug' sm={2}>
            Slug navn
          </Label>
          <Col sm={10}>
            <Input
              className={this.state.brandSlugError ? 'is-invalid' : ''}
              required
              type='Text'
              name='brandSlug'
              id='brandSlug'
              placeholder='fx. msb'
              value={this.state.brandDetails.brandSlug}
              onChange={BrandActions.updateField}
              onBlur={BrandActions.fieldBlur}
            />
            <Fade in={this.state.brandSlugError ? true : false} tag='small' className='error-banner'>
              Indtast mellem 1-20 tegn
            </Fade>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label for='customDomain' sm={2}>
            Klient Domæne
          </Label>
          <Col sm={10}>
            <Input
              type='Text'
              name='customDomain'
              id='customDomain'
              placeholder='fx. msb.upgrader.dk'
              value={this.state.brandDetails.customDomain}
              onChange={BrandActions.updateField}
            />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label className='required' for='brandNameShort' sm={2}>
            Kort navn
          </Label>
          <Col sm={10}>
            <Input
              className={this.state.brandNameShortError ? 'is-invalid' : ''}
              required
              type='Text'
              name='brandNameShort'
              id='brandNameShort'
              placeholder='fx. MSB'
              value={this.state.brandDetails.brandNameShort}
              onChange={BrandActions.updateField}
              onBlur={BrandActions.fieldBlur}
            />
            <Fade in={this.state.brandNameShortError ? true : false} tag='small' className='error-banner'>
              Indtast mellem 1-11 tegn
            </Fade>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label className='required' for='brandNickname' sm={2}>
            Kalde navn
          </Label>
          <Col sm={10}>
            <Input
              className={this.state.brandNicknameError ? 'is-invalid' : ''}
              required
              type='text'
              name='brandNickname'
              id='brandNickname'
              placeholder='fx. MS'
              value={this.state.brandDetails.brandNickname}
              onChange={BrandActions.updateField}
              onBlur={BrandActions.fieldBlur}
            />
            <Fade in={this.state.brandNicknameError ? true : false} tag='small' className='error-banner'>
              Indtast mellem 1-11 tegn
            </Fade>
          </Col>
        </FormGroup>

        <hr />
        {showThemeField && (
          <>
            <FormGroup row>
              <Label className='required' for='brandNickname' sm={2}>
                Brand Theme
              </Label>
              <Col sm={10}>
                <CustomInput
                  type='select'
                  id='brandTheme'
                  name='brandTheme'
                  value={this.state.brandDetails.brandTheme ? this.state.brandDetails.brandTheme : ''}
                  onChange={BrandActions.updateField}
                >
                  <option value={null}>System Default</option>
                  {['ThemeONE', 'ThemeTWO', 'ThemeTHREE', 'ThemeFOUR'].map((e, i) => {
                    return (
                      <option value={e} key={i}>
                        {e}
                      </option>
                    )
                  })}
                </CustomInput>
              </Col>
            </FormGroup>
          </>
        )}
        <FormGroup row>
          <Label for='Language' sm={2}>
            Languages
          </Label>
          <Col sm={10}>
            {/* <CustomInput
              type='select'
              id='enabledLanguages'
              name='enabledLanguages'
              value={this.state.brandDetails.enabledLanguages ? this.state.brandDetails.enabledLanguages : ''}
              onChange={BrandActions.updateField}
            >
              <option value={null}>Click to Select</option>
              {this.state.languages &&
                this.state.languages.length &&
                this.state.languages.map((e, i) => {
                  return (
                    <option value={e.enabledLanguages} key={i}>
                      {e.languageName}
                    </option>
                  )
                })}
            </CustomInput> */}

            <StyledDropdown
              data={
                !this.state.languages.length
                  ? { value: '' }
                  : {
                      label: 'All',
                      value: 'all',
                      expanded: true,
                      children: this.state.languages.map(item => ({ label: item.languageName, value: item.LanguageID, checked: item.checked }))
                    }
              }
              onChange={item => BrandActions.updateEnabledLanguages(item)}
              keepTreeOnSearch={true}
              keepChildrenOnSearch={true}
              showPartiallySelected={true}
              texts={{
                placeholder: 'Select Languages',
                noMatches: 'no language found',
                labelRemove: 'remove'
              }}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for='Language' sm={2}>
            Default Language
          </Label>
          <Col sm={10}>
            <CustomInput
              type='select'
              id='LanguageID'
              name='LanguageID'
              value={this.state.brandDetails.LanguageID ? this.state.brandDetails.LanguageID : ''}
              onChange={BrandActions.updateField}
            >
              <option value=''>Click to Select</option>
              {this.state.languages &&
                this.state.languages.length &&
                this.state.languages.map((e, i) => {
                  if (!e.checked) return
                  return (
                    <option value={e.LanguageID} key={i}>
                      {e.languageName}
                    </option>
                  )
                })}
            </CustomInput>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label for='Color' sm={2}>
            Farve
          </Label>
          <Col sm={8}>
            <Input
              className={this.state.brandColorError ? 'is-invalid' : ''}
              type='text'
              name='brandColor'
              id='brandColor'
              value={this.state.brandDetails.brandColor ? this.state.brandDetails.brandColor : ''}
              onChange={BrandActions.updateField}
              onBlur={BrandActions.fieldBlur}
            />
            <Fade in={this.state.brandColorError ? true : false} tag='small' className='error-banner'>
              Indtast venligst korrekt format for farve. fx F3F3F1
            </Fade>
          </Col>
          <Col sm={2}>
            <Input
              className={this.state.brandColorError ? 'is-invalid' : ''}
              type='color'
              name='colorPicker'
              id='colorPicker'
              value={this.state.brandDetails.brandColor ? '#' + this.state.brandDetails.brandColor : '#FFFFFF'}
              onChange={BrandActions.updateField}
              onBlur={BrandActions.fieldBlur}
            />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label for='brandHeaderFont' sm={2}>
            Overskrift skrifttype
          </Label>
          <Col sm={6}>
            <Input
              type='select'
              id='brandHeaderFont'
              name='brandHeaderFont'
              value={this.state.brandDetails.brandHeaderFont ? this.state.brandDetails.brandHeaderFont : ''}
              onChange={BrandActions.updateField}
              onBlur={BrandActions.fieldBlur}
            >
              <option value=''>Click to Select</option>
              {this.state.fonts &&
                this.state.fonts.length &&
                this.state.fonts.map((font, i) => {
                  return (
                    <option value={font.FontID} key={i}>
                      {`${font.fontName}`}
                    </option>
                  )
                })}
            </Input>
            <Fade in={this.state.brandHeaderFontError ? true : false} tag='small' className='error-banner'>
              Indtast venligst korrekt format for farve. fx F3F3F1
            </Fade>
          </Col>
          <Col sm={4}>
            <Input
              type='number'
              name='brandHeaderFontSize'
              id='brandHeaderFontSize'
              placeholder='Font size in px'
              onChange={BrandActions.updateField}
              onBlur={BrandActions.fieldBlur}
              value={this.state.brandDetails.brandHeaderFontSize ? this.state.brandDetails.brandHeaderFontSize : ''}
            />
            <Fade in={this.state.brandHeaderFontSizeError ? true : false} tag='small' className='error-banner'>
              Indtast venligst korrekt format for farve. fx F3F3F1
            </Fade>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label for='brandBodyFont' sm={2}>
            Brødskrift
          </Label>
          <Col sm={6}>
            <Input
              type='select'
              id='brandBodyFont'
              name='brandBodyFont'
              value={this.state.brandDetails.brandBodyFont ? this.state.brandDetails.brandBodyFont : ''}
              onChange={BrandActions.updateField}
              onBlur={BrandActions.fieldBlur}
            >
              <option value=''>Click to Select</option>
              {this.state.fonts &&
                this.state.fonts.length &&
                this.state.fonts.map((font, i) => {
                  return (
                    <option value={font.FontID} key={i}>
                      {`${font.fontName}`}
                    </option>
                  )
                })}
            </Input>
            <Fade in={this.state.brandBodyFontError ? true : false} tag='small' className='error-banner'>
              Indtast venligst korrekt format for farve. fx F3F3F1
            </Fade>
          </Col>
          <Col sm={4}>
            <Input
              type='number'
              name='brandBodyFontSize'
              id='brandBodyFontSize'
              placeholder='Font size in px'
              onChange={BrandActions.updateField}
              onBlur={BrandActions.fieldBlur}
              value={this.state.brandDetails.brandBodyFontSize ? this.state.brandDetails.brandBodyFontSize : ''}
            />
            <Fade in={this.state.brandBodyFontSizeError ? true : false} tag='small' className='error-banner'>
              Indtast venligst korrekt format for farve. fx F3F3F1
            </Fade>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label for='brandLogo' sm={2}>
            Logo
          </Label>
          <Col sm={10}>
            <Input
              formNoValidate
              className={this.state.brandLogoError ? 'is-invalid' : ''}
              type='URL'
              name='brandLogo'
              id='brandLogo'
              placeholder='fx.https://www.msb.dk/msbranding1.png'
              value={this.state.brandDetails.brandLogo}
              onChange={BrandActions.updateField}
              onBlur={BrandActions.fieldBlur}
            />
            <Fade in={this.state.brandLogoError ? true : false} tag='small' className='error-banner'>
              Indtast venligst et korrekt URL
            </Fade>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label for='brandIconImageURL' sm={2}>
            URL billede
          </Label>
          <Col sm={10}>
            <Input
              formNoValidate
              className={this.state.brandIconImageURLError ? 'is-invalid' : ''}
              type='URL'
              name='brandIconImageURL'
              id='brandIconImageURL'
              placeholder='fx. https://www.msb.dk/msbranding.png'
              value={this.state.brandDetails.brandIconImageURL}
              onChange={BrandActions.updateField}
              onBlur={BrandActions.fieldBlur}
            />
            <Fade in={this.state.brandIconImageURLError ? true : false} tag='small' className='error-banner'>
              Indtast venligst et korrekt URL
            </Fade>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for='termsOfTradeURL' sm={2}>
            Handelsbetingelserne URL
          </Label>
          <Col sm={10}>
            <Input
              formNoValidate
              type='URL'
              name='TermsConditionsURL'
              id='TermsConditionsURL'
              placeholder='fx. https://https://docs.google.com/document/u/1/d/xxxx/pub'
              value={this.state.tncCustomisation.TermsConditionsURL}
              onChange={BrandActions.updateTncCustomisation}
            />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label for='termsOfTradeURL' sm={2}>
            Cookie Policy Content
          </Label>
          <Col sm={10}>
            <Input
              formNoValidate
              type='text'
              name='header'
              id='cookiePageHeader'
              placeholder='Cookie policy page headline'
              value={this.state.cookieCustomisation.cookiePageClub?.header}
              onChange={BrandActions.updateCookieCustomisation}
            />
          </Col>
          <Label for='termsOfTradeURL' sm={2}></Label>
          <Col sm={10} style={{ marginTop: '10px' }}>
            <Input
              formNoValidate
              type='textarea'
              name='content'
              id='cookiePageContent'
              placeholder='Cookie policy page content'
              value={this.state.cookieCustomisation.cookiePageClub?.content}
              onChange={BrandActions.updateCookieCustomisation}
            />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label for='brandFeatureImageURL' sm={2}>
            Feature billede
          </Label>
          <Col sm={10}>
            <Input
              formNoValidate
              className={this.state.brandFeatureImageURLError ? 'is-invalid' : ''}
              type='URL'
              name='brandFeatureImageURL'
              id='brandFeatureImageURL'
              placeholder='fx. https://www.msb.dk/msbranding.png'
              value={this.state.brandDetails.brandFeatureImageURL}
              onChange={BrandActions.updateField}
              onBlur={BrandActions.fieldBlur}
            />
            <Fade in={this.state.brandFeatureImageURLError ? true : false} tag='small' className='error-banner'>
              Indtast venligst et korrekt URL
            </Fade>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label for='faviconImageURL' sm={2}>
            Favicon
          </Label>
          <Col sm={10}>
            <Input
              name=''
              field='upload'
              type='file'
              data={{ upload: '' }}
              noValue={this.state.faviconImageURL !== ''}
              onChange={BrandActions.uploadFaviconImage}
              disabled={this.state.isUploading}
              accept='image/*'
            />
            <img
              src={this.state.brandDetails.faviconImageURL}
              alt={this.state.brandDetails.faviconImageURL}
              style={{
                height: '100px',
                width: '100px',
                objectFit: 'cover',
                marginTop: '10px',
                marginBottom: '10px',
                display: this.state.brandDetails.faviconImageURL ? 'inline-block' : 'none'
              }}
            />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label for='userInactivityTimeoutMonths' sm={2}>
            Bruger inaktivitet timeout
          </Label>
          <Col sm={10}>
            <Input
              formNoValidate
              className={this.state.userInactivityTimeoutMonthsError ? 'is-invalid' : ''}
              type='text'
              name='userInactivityTimeoutMonths'
              id='userInactivityTimeoutMonths'
              placeholder='måneder'
              value={this.state.brandDetails.userInactivityTimeoutMonths}
              onChange={BrandActions.updateField}
              onBlur={BrandActions.fieldBlur}
            />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label for='glsCustomerNumber' sm={2}>
            Afsendernummer
          </Label>
          <Col sm={10}>
            <Input
              formNoValidate
              className={this.state.glsCustomerNumberError ? 'is-invalid' : ''}
              type='URL'
              name='glsCustomerNumber'
              id='glsCustomerNumber'
              placeholder='Afsendernummer'
              value={this.state.brandDetails.glsCustomerNumber}
              onChange={BrandActions.updateField}
              onBlur={BrandActions.fieldBlur}
            />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label className='required' for='orderShipmentType' sm={2}>
            GLS Forsendelsestype
          </Label>
          <Col sm={10}>
            <CustomInput
              type='select'
              id='orderShipmentType'
              name='orderShipmentType'
              value={this.state.brandDetails.orderShipmentType}
              onChange={BrandActions.updateField}
            >
              <option value='A'>Almindelig</option>
              <option value='U'>Udland</option>
              <option value='Z'>Pakkeshop</option>
              <option value='DS'>DirectShop</option>
            </CustomInput>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label className='required' for='orderParcelType' sm={2}>
            GLS Pakketype
          </Label>
          <Col sm={10}>
            <CustomInput
              type='select'
              id='orderParcelType'
              name='orderParcelType'
              value={this.state.brandDetails.orderParcelType}
              onChange={BrandActions.updateField}
            >
              <option value='A'>Almindelig</option>
              <option value='S'>Små pakker</option>
              <option value='M'>Privat leverance</option>
            </CustomInput>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label className='required' for='orderGLSService' sm={2}>
            GLS Services
          </Label>
          <Col sm={10}>
            <CustomInput
              type='select'
              id='orderGLSService'
              name='orderGLSService'
              value={this.state.brandDetails.orderGLSService}
              onChange={BrandActions.updateField}
            >
              <option value='E'>Mail notifikation</option>
              <option value='B'>Shop retur</option>
              <option value='A'>Kun modtager</option>
              <option value='C'>Deposit service</option>
            </CustomInput>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for='useCustomOrderShipMessage' sm={2}>
            Override Order Ship Message
          </Label>
          <Col sm={10}>
            <StyledCustomInput
              type='switch'
              checked={this.state.brandDetails && this.state.brandDetails.useCustomOrderShipMessage ? true : false}
              id='useCustomOrderShipMessage'
              name='useCustomOrderShipMessage'
              onChange={BrandActions.updateField}
            />
          </Col>
        </FormGroup>
        <OrderShipMessage brandDetails={this.state.brandDetails} languages={this.state.languages} updateField={BrandActions.updateField} />
        <hr />

        <FormGroup row>
          <Label for='freeFreightOrderLimit' sm={2}>
            Free Freight Order Limit
          </Label>
          <Col sm={10}>
            <Input
              type='Text'
              name='freeFreightOrderLimit'
              id='freeFreightOrderLimit'
              placeholder=''
              value={this.state.brandDetails.freeFreightOrderLimit}
              onChange={BrandActions.updateField}
              onBlur={BrandActions.fieldBlur}
            />
          </Col>
        </FormGroup>

        <BrandFreightIntervals freightIntervals={this.state.freightIntervals} updateFreightInterval={BrandActions.updateFreightIntervalField} />

        <hr />

        <FormGroup row>
          <Label for='brandPointRatio' sm={2}>
            Point Ratio
          </Label>
          <Col sm={10}>
            <Input
              className={this.state.brandPointRatio ? 'is-invalid' : ''}
              type='Text'
              name='brandPointRatio'
              id='brandPointRatio'
              placeholder='fx. 1.5'
              value={this.state.brandDetails.brandPointRatio}
              onChange={BrandActions.updateField}
              onBlur={BrandActions.fieldBlur}
            />
            <Fade in={this.state.brandPointRatioError ? true : false} tag='small' className='error-banner'>
              Indtast venligst kun tal med eller uden et punktum
            </Fade>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for='brandShopSelectionLimit' sm={2}>
            Shop grænse
          </Label>
          <Col sm={10}>
            <Input
              className={this.state.brandShopSelectionLimitError ? 'is-invalid' : ''}
              required
              type='Text'
              name='brandShopSelectionLimit'
              id='brandShopSelectionLimit'
              placeholder='fx. 1'
              value={this.state.brandDetails.brandShopSelectionLimit}
              onChange={BrandActions.updateField}
              onBlur={BrandActions.fieldBlur}
            />
            <Fade in={this.state.brandShopSelectionLimitError ? true : false} tag='small' className='error-banner'>
              Indtast venligst kun tal
            </Fade>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label for='poolOrder' sm={2}>
            Rækkefølge
          </Label>
          <Col sm={10}>
            <Input
              className={this.state.poolOrderError ? 'is-invalid' : ''}
              required
              type='Text'
              name='poolOrder'
              id='poolOrder'
              placeholder='fx. 0'
              value={this.state.brandDetails.poolOrder}
              onChange={BrandActions.updateField}
              onBlur={BrandActions.fieldBlur}
            />
            <Fade in={this.state.poolOrderError ? true : false} tag='small' className='error-banner'>
              Indtast venligst kun tal
            </Fade>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for='timeStart' sm={2}>
            Start dato
          </Label>
          <Col sm={10}>
            <Input
              disabled={this.state.brandDetails.BrandID ? true : false}
              className={this.state.timeStartError ? 'is-invalid' : ''}
              type='date'
              name='timeStart'
              id='timeStart'
              value={this.state.brandDetails.timeStart}
              onChange={BrandActions.updateField}
              onBlur={BrandActions.fieldBlur}
            />
            <Fade in={this.state.timeStartError ? true : false} tag='small' className='error-banner'>
              Udfyld venligst en korrekt dato
            </Fade>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for='date' sm={2}>
            Slut dato
          </Label>
          <Col sm={10}>
            <Input
              className={this.state.timeEndError ? 'is-invalid' : ''}
              type='date'
              name='timeEnd'
              id='timeEnd'
              value={this.state.brandDetails.timeEnd ? this.state.brandDetails.timeEnd : ''}
              onChange={BrandActions.updateField}
              onBlur={BrandActions.fieldBlur}
            />
            <Fade in={this.state.timeEndError ? true : false} tag='small' className='error-banner'>
              Udfyld venligst en korrekt dato
            </Fade>
          </Col>
        </FormGroup>
        <hr />
        <FormGroup row>
          <Label for='brandAccessKey' sm={2}>
            Adgangskode
          </Label>
          <Col sm={10}>
            <Input
              className={this.state.brandAccessKeyError ? 'is-invalid' : ''}
              type='text'
              name='brandAccessKey'
              id='brandAccessKey'
              value={this.state.brandDetails.brandAccessKey}
              onChange={BrandActions.updateField}
              onBlur={BrandActions.fieldBlur}
            />
            <Fade in={this.state.brandAccessKeyError ? true : false} tag='small' className='error-banner'>
              Angiv et 6 cifret kode
            </Fade>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for='brandAccessTermsURL' sm={2}>
            Adgangsvilkår URL
          </Label>
          <Col sm={10}>
            <Input
              formNoValidate
              className={this.state.brandAccessTermsURLError ? 'is-invalid' : ''}
              type='URL'
              name='brandAccessTermsURL'
              id='brandAccessTermsURL'
              placeholder='fx. https://www.msbranding.com'
              value={this.state.brandDetails.brandAccessTermsURL}
              onChange={BrandActions.updateField}
              onBlur={BrandActions.fieldBlur}
            />
            <Fade in={this.state.brandAccessTermsURLError ? true : false} tag='small' className='error-banner'>
              Udfyld venligst et korrekt URL
            </Fade>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label for='brandAccess' sm={2}>
            Adgang
          </Label>
          <Col sm={10}>
            <div>
              <CustomInput
                type='radio'
                id='brandAccess.ACTIVE'
                name='brandAccess'
                value='ACTIVE'
                checked={this.state.brandDetails.brandAccess === 'ACTIVE'}
                label='Aktiv'
                onChange={BrandActions.updateField}
              />
              <CustomInput
                type='radio'
                id='brandAccess.INACTIVE'
                name='brandAccess'
                value='INACTIVE'
                checked={this.state.brandDetails.brandAccess === 'INACTIVE'}
                label='Inaktiv'
                onChange={BrandActions.updateField}
              />
              <CustomInput
                type='radio'
                id='brandAccess.SUSPENDED'
                name='brandAccess'
                value='SUSPENDED'
                checked={this.state.brandDetails.brandAccess === 'SUSPENDED'}
                label='Suspenderet'
                onChange={BrandActions.updateField}
              />
              <CustomInput
                type='radio'
                id='brandAccess.ARCHIVED'
                name='brandAccess'
                value='ARCHIVED'
                checked={this.state.brandDetails.brandAccess === 'ARCHIVED'}
                label='Arkiveret'
                onChange={BrandActions.updateField}
              />
            </div>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label for='brandAccessType' sm={2}>
            Typeadgang
          </Label>
          <Col>
            <div>
              <CustomInput
                type='radio'
                id='brandAccessType.Private'
                name='brandAccessType'
                value='PRIVATE'
                checked={this.state.brandDetails.brandAccessType === 'PRIVATE'}
                label='Privat'
                onChange={BrandActions.updateField}
              />
              <CustomInput
                type='radio'
                id='brandAccessType.Protected'
                name='brandAccessType'
                value='PROTECTED'
                checked={this.state.brandDetails.brandAccessType === 'PROTECTED'}
                label='Beskyttet'
                onChange={BrandActions.updateField}
              />
              <CustomInput
                type='radio'
                id='brandAccessType.public'
                name='brandAccessType'
                value='PUBLIC'
                checked={this.state.brandDetails.brandAccessType === 'PUBLIC'}
                label='Offentlig'
                onChange={BrandActions.updateField}
              />
              <CustomInput
                type='radio'
                id='brandAccessType.anonym'
                name='brandAccessType'
                value='ANONYMOUS'
                checked={this.state.brandDetails.brandAccessType === 'ANONYMOUS'}
                label='Anonym'
                onChange={BrandActions.updateField}
              />
            </div>
          </Col>
        </FormGroup>
        <hr />
        <FormGroup row>
          <Label for='brandMuted' sm={2}>
            Stum
          </Label>
          <Col>
            <div>
              <CustomInput
                type='radio'
                id='brandMuted.nej'
                name='brandMuted'
                value={0}
                checked={Number(this.state.brandDetails.brandMuted === 0)}
                label='Nej'
                onChange={BrandActions.updateField}
              />
              <CustomInput
                type='radio'
                id='brandMuted.ja'
                name='brandMuted'
                value={1}
                checked={Number(this.state.brandDetails.brandMuted === 1)}
                label='Ja'
                onChange={BrandActions.updateField}
              />
            </div>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label for='isChristmas' sm={2}>
            Jul
          </Label>
          <Col>
            <div>
              <CustomInput
                type='radio'
                id='isChristmas.nej'
                name='isChristmas'
                value={0}
                checked={Number(this.state.brandDetails.isChristmas === 0)}
                label='Nej'
                onChange={BrandActions.updateField}
              />
              <CustomInput
                type='radio'
                id='isChristmas.ja'
                name='isChristmas'
                value={1}
                checked={Number(this.state.brandDetails.isChristmas === 1)}
                label='Ja'
                onChange={BrandActions.updateField}
              />
            </div>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label for='brandDemo' sm={2}>
            Demo
          </Label>
          <Col>
            <div>
              <CustomInput
                type='radio'
                id='brandDemo.nej'
                name='brandDemo'
                value={0}
                checked={Number(this.state.brandDetails.brandDemo === 0)}
                label='Nej'
                onChange={BrandActions.updateField}
              />
              <CustomInput
                type='radio'
                id='brandDemo.ja'
                name='brandDemo'
                value={1}
                checked={Number(this.state.brandDetails.brandDemo === 1)}
                label='Ja'
                onChange={BrandActions.updateField}
              />
            </div>
          </Col>
        </FormGroup>
        <hr />
        <FormGroup row>
          <Label for='contactEmail' sm={2}>
            Support Email
          </Label>
          <Col>
            <div>
              <Input
                formNoValidate
                className={this.state.contactEmailError ? 'is-invalid' : ''}
                type='email'
                name='contactEmail'
                id='contactEmail'
                placeholder='Contact Email for Support Queries'
                value={this.state.brandDetails.contactEmail}
                onChange={BrandActions.updateField}
                onBlur={BrandActions.fieldBlur}
              />
              <Fade in={this.state.contactEmailError ? true : false} tag='small' className='error-banner'>
                Udfyld venligst et korrekt Email
              </Fade>
            </div>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for='contactPhone' sm={2}>
            Support Phone
          </Label>
          <Col>
            <div>
              <Input
                formNoValidate
                className={this.state.contactPhoneError ? 'is-invalid' : ''}
                type='text'
                name='contactPhone'
                id='contactPhone'
                placeholder='Contact Phone for Support Queries'
                value={this.state.brandDetails.contactPhone}
                onChange={BrandActions.updateField}
                onBlur={BrandActions.fieldBlur}
              />
              <Fade in={this.state.contactPhoneError ? true : false} tag='small' className='error-banner'>
                Udfyld venligst et korrekt Phone
              </Fade>
            </div>
          </Col>
        </FormGroup>
        <hr />
        <FormGroup row>
          <Label for='openingHoursMonThurs' sm={2}>
            Opening hours
          </Label>
          <Label sm={1} style={{ textAlign: 'end' }}>
            Monday-Thursday
          </Label>
          <Col sm={3}>
            <div>
              <Input
                formNoValidate
                name='openingHoursMonThurs'
                id='openingHoursMonThurs'
                placeholder='Monday-Thursday'
                value={this.state.openingHoursCustomisation ? this.state.openingHoursCustomisation.openingHoursMonThurs : null}
                onChange={BrandActions.updateOpeningHoursCustomisation}
              />
            </div>
          </Col>
          <Label sm={1} style={{ textAlign: 'end' }}>
            Friday
          </Label>
          <Col sm={3}>
            <div style={{ flexDirection: 'column' }}>
              <Input
                formNoValidate
                name='openingHoursFri'
                id='openingHoursFri'
                placeholder='Friday'
                value={this.state.openingHoursCustomisation ? this.state.openingHoursCustomisation.openingHoursFri : null}
                onChange={BrandActions.updateOpeningHoursCustomisation}
              />
            </div>
          </Col>
        </FormGroup>
        <Button onClick={BrandActions.saveBrand} disabled={!formIsValid}>
          {this.state.status === STATUS.IS_SAVING ? <FontAwesomeIcon icon='spinner' spin /> : 'Gem'}
        </Button>
        <Button color='danger' className='ml-2' onClick={this.resetForm}>
          Nulstil
        </Button>
      </Form>
    )
  }
}

function OrderShipMessage(props) {
  const { brandDetails, updateField } = props
  const EnabledLanguages = brandDetails.enabledLanguages
  const [translations, setTranslations] = useState([])
  const [loading, setLoading] = useState(true)
  const [availableLanguages, setAvailableLanguages] = useState([])

  const fetchAllLanguage = () => {
    Proxies.GET('schemas/languages').then(responseJSON => {
      setLoading(false)
      if (!responseJSON.error) {
        const languages = responseJSON.filter(obj => EnabledLanguages.includes(obj.LanguageID))
        setAvailableLanguages(languages)
        if (brandDetails.translations && brandDetails.translations.length) {
          setTranslations(brandDetails.translations)
        } else {
          setTranslations([
            {
              LanguageID: languages?.length ? languages[0].LanguageID : '',
              orderShipMessage: brandDetails.orderShipMessage ? brandDetails.orderShipMessage : ''
            }
          ])
        }
      }
    })
  }

  useEffect(() => {
    if (EnabledLanguages && EnabledLanguages.length) {
      fetchAllLanguage()
    }
  }, [EnabledLanguages])

  useEffect(() => {
    updateField({ name: 'translations', value: translations })
  }, [translations])

  return (
    <>
      <Label className='required' for='orderShipMessage'>
        Leveringsbesked
      </Label>
      <div style={{ float: 'right' }}>
        <Button
          onClick={() => {
            setTranslations([...translations, {}])
          }}
        >
          <FontAwesomeIcon icon='plus' />
        </Button>
      </div>
      <br></br>
      <div style={{ marginLeft: '16%' }}>
        <FormGroup row>
          {loading || !translations || !translations.length ? (
            <FontAwesomeIcon icon='spinner' spin />
          ) : (
            translations.map((translation, idx) => {
              return (
                <Col sm={12} key={idx}>
                  <FormGroup row>
                    <Label for='LanguageID' sm={2}>
                      Select Language
                    </Label>
                    <Col sm={9}>
                      <Row>
                        <Col sm={11}>
                          <Input
                            className={translation.LanguageID ? 'is-valid' : 'is-invalid'}
                            type='select'
                            id='LanguageID'
                            name='LanguageID'
                            value={translation.LanguageID ? translation.LanguageID : ''}
                            onChange={e => {
                              setTranslations(
                                translations.map((tr, i) => {
                                  if (i === idx) {
                                    tr.LanguageID = e.target.value
                                  }
                                  return tr
                                })
                              )
                            }}
                          >
                            <option value=''>Click to Select</option>
                            {availableLanguages &&
                              availableLanguages.length &&
                              availableLanguages.map((lan, i) => {
                                return (
                                  <option value={lan.LanguageID} key={i}>
                                    {`${lan.languageKey} - ${lan.languageName}`}
                                  </option>
                                )
                              })}
                          </Input>
                        </Col>
                      </Row>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for='Language' sm={2}>
                      Text
                    </Label>
                    <Col sm={9}>
                      <Row>
                        <Col sm={11}>
                          <Input
                            type='textarea'
                            name='orderShipMessage'
                            id='orderShipMessage'
                            value={translation.orderShipMessage ? translation.orderShipMessage : ''}
                            onChange={e => {
                              setTranslations(
                                translations.map((tr, i) => {
                                  if (i === idx) {
                                    tr.orderShipMessage = e.target.value
                                  }
                                  return tr
                                })
                              )
                            }}
                          />
                        </Col>
                        <Col sm={1}>
                          <Button
                            onClick={() =>
                              setTranslations(
                                translations.filter((tr, i) => {
                                  if (i !== idx) {
                                    return tr
                                  }
                                })
                              )
                            }
                            color='danger'
                          >
                            <FontAwesomeIcon icon='minus' />
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </FormGroup>
                </Col>
              )
            })
          )}
        </FormGroup>
      </div>
    </>
  )
}

function BrandFreightIntervals({ freightIntervals, updateFreightInterval }) {
  return (
    <FormGroup row>
      <Label for='freeFreightOrderLimit' sm={2}>
        Freight Intervals
      </Label>
      <Col sm={10}>
        <div style={{ float: 'right' }}>
          <Button
            onClick={() => {
              BrandActions.addFreightIntervalRow()
            }}
          >
            <FontAwesomeIcon icon='plus' />
          </Button>
        </div>
        {!!freightIntervals &&
          !!freightIntervals.length &&
          freightIntervals.map((freight, idx) => {
            return (
              <Col key={idx} style={{ marginTop: 24 }} sm={6}>
                <Row style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
                  <Col sm={11}>
                    <Label for='title'>Title</Label>
                    <Input
                      type='Text'
                      name='title'
                      id={`title_${idx}`}
                      placeholder='From 1kg to 5kg'
                      value={freight.title}
                      onChange={e =>
                        updateFreightInterval(
                          freightIntervals.map((f, i) => {
                            if (i === idx) {
                              f.title = e.target.value
                            }
                            return f
                          })
                        )
                      }
                      onBlur={BrandActions.fieldBlur}
                    />
                  </Col>
                  <Col sm={1}>
                    <Button onClick={() => updateFreightInterval(freightIntervals.filter((f, index) => idx !== index))} color='danger'>
                      <FontAwesomeIcon icon='minus' />
                    </Button>
                  </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                  <Col sm={3}>
                    <Label for='orderWeightFrom'>Order Weight from</Label>
                    <Input
                      className={freight.orderWeightFrom < 0 ? 'is-invalid' : ''}
                      type='number'
                      name='orderWeightFrom'
                      id={`orderWeightFrom_${idx}`}
                      placeholder='0'
                      value={freight.orderWeightFrom}
                      onChange={e =>
                        updateFreightInterval(
                          freightIntervals.map((f, i) => {
                            if (i === idx) {
                              f.orderWeightFrom = e.target.value
                            }
                            return f
                          })
                        )
                      }
                      onBlur={BrandActions.fieldBlur}
                    />
                  </Col>
                  <Col sm={3}>
                    <Label for='orderWeightTo'>Order Weight to</Label>
                    <Input
                      className={!freight.orderWeightTo ? 'is-invalid' : ''}
                      type='number'
                      name='orderWeightTo'
                      id={`orderWeightTo_${idx}`}
                      placeholder='0'
                      value={freight.orderWeightTo}
                      onChange={e =>
                        updateFreightInterval(
                          freightIntervals.map((f, i) => {
                            if (i === idx) {
                              f.orderWeightTo = e.target.value
                            }
                            return f
                          })
                        )
                      }
                      onBlur={BrandActions.fieldBlur}
                    />
                  </Col>
                  <Col sm={3}>
                    <Label for='freightPoints'>Freight points</Label>
                    <Input
                      className={!freight.freightPoints ? 'is-invalid' : ''}
                      type='number'
                      name='freightPoints'
                      id={`freightPoints_${idx}`}
                      placeholder='0'
                      value={freight.freightPoints}
                      onChange={e =>
                        updateFreightInterval(
                          freightIntervals.map((f, i) => {
                            if (i === idx) {
                              f.freightPoints = e.target.value
                            }
                            return f
                          })
                        )
                      }
                      onBlur={BrandActions.fieldBlur}
                    />
                  </Col>
                  <Col sm={3}>
                    <Label for='freightPriceDKK'>Freight price (DKK)</Label>
                    <Input
                      className={!freight.freightPriceDKK ? 'is-invalid' : ''}
                      type='number'
                      name='freightPriceDKK'
                      id={`freightPriceDKK${idx}`}
                      placeholder='0'
                      value={freight.freightPriceDKK}
                      onChange={e =>
                        updateFreightInterval(
                          freightIntervals.map((f, i) => {
                            if (i === idx) {
                              f.freightPriceDKK = e.target.value
                            }
                            return f
                          })
                        )
                      }
                      onBlur={BrandActions.fieldBlur}
                    />
                  </Col>
                </Row>
              </Col>
            )
          })}
      </Col>
    </FormGroup>
  )
}
